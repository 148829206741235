<template>
  <b-row class="acc-objectifs-view full-width-row">
    <master-title-2>Accomagnement pour</master-title-2>
    <p class="acc-tb-objectif large-text-center">Lancer une initiative similaire</p>
    <p class="acc-tb-objectif large-text-center">Mener des actions en lien avec l'alimentation auprès des étudiant.e.s</p>
    <legumes-line />
    <div style="height: 1rem;" />
    <master-title-2 v-if="nextTitle">Exemples d'accompagnements / témoignages réalisés</master-title-2>
    <div v-else style="height: 1rem;" />
  </b-row>
</template>

<script>
import LegumesLine from '@/components/utils/LegumesLine.vue'
import MasterTitle2 from '@/components/utils/MasterTitle2.vue'
export default {
  components: { MasterTitle2, LegumesLine },
  name: "AccompagnerObjectifs",
  props: {
    nextTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    start_route() {
      window.location.href = "/la-route";
    },
  },
}
</script>

<style scoped>
.acc-objectifs-view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: linear-gradient(20deg, #ffffff 0%, #ffffff 30%, var(--color-beige-light) 30%, var(--color-beige-light) 100%);
}

.acc-tb-objectif {
  margin-bottom: 3rem;
  font-size: 1.6rem;
}
</style>