<template>
  <b-container fluid="sm" id="asso-page">
    <photo-title
      :image="require('@/../public/assets/photos/sensibiliser_low.jpg')"
    >Sensibiliser</photo-title>

    <b-row class="justify-content-md-center" style="margin-top: 1rem;">
      <b-col lg=12 align="center">
        <p class="large-text">L'association développe et déploie des actions de sensibilisation sur les campus de l'Université de Lorraine, dans les lieux de vie étudiants ou lors d'événements.  Dans un esprit de convivialité, d'entraide et de partage, nous souhaitons ainsi donner les clés aux étudiant.es pour leur redonner du pouvoir d'agir comme consom'acteur.rice citoyen.ne. 
Nous défendons des systèmes alimentaires durables, solidaires et résilients, à la croisée de nombreux enjeux : santé publique, accessibilité alimentaire, protection des ressources, préservation de la biodiversité, changement climatique, juste rémunération des agriculteur.rice.s, etc.</p>
        <photo-text :image="require('@/../public/assets/sensibiliser/sensi_1.jpg')" width="60%"/>
        <p class="large-text">Plusieurs formats de sensibilisation sont proposés :</p>
        <master-title-2>Sur le temps du déjeuner</master-title-2>
        <p class="large-text">Les activités sur le temps du déjeuner en lien avec le foodtruck avec, par exemple, des journées thématiques, comme la Saint Valentin, le repas de Noël ou encore Pâques. Il s'agit de proposer aux étudiant.e.s des repas plus élaborés avec pour objectif de faire des animations simples, ludiques et pédagogiques telles que des quizz, des jeux ou des fiches informatives sur les légumes permettant de les sensibiliser directement. Par ailleurs, la participation au Grand repas de Lorraine est un événement phare en restauration collective, permettant de mettre à l'honneur des recettes concoctées par des chefs cuisiniers et auquel l'association se joint.</p>
        <master-title-2>Les ateliers de cuisine</master-title-2>
        <p class="large-text">Des ateliers de cuisine ont été mis en place par les cuisinier.ière.s du foodtruck permettant aux étudiant.e.s de cuisiner, échanger et discuter autour des différents sujets que porte l'association comme l'alimentation saine et durable, la précarité alimentaire, la nutrition, la santé, l'environnement, etc. Les ateliers sont réalisés à la demande d'associations étudiantes et/ou de cursus universitaires. Pour chaque atelier, pouvant accueillir 15 étudiant.e.s maximum, un thème est défini (ex : atelier anti-gaspillage, cuisine française, etc). Les ateliers se découpent en 4 parties : cuisine avec les étudiant.e.s, temps de formation, dégustation, échanges avec les étudiant.e.s.</p>
        <photo-text :image="require('@/../public/assets/sensibiliser/atelier_cuisine.jpg')" width="40%"/>
        <master-title-2>Les buffets éducatifs</master-title-2>
        <p class="large-text">Des buffets éducatifs ont été inventés et testés pour permettre de répondre aux demandes de “buffet traiteur”, généralement organisés pour une cinquantaine de personnes ou plus. Le principe est d'accompagner chaque mets d'une activité et/ ou infographie afin de sensibiliser et d'informer le.a consommateur.rice. Ce format permet de lier dégustation et sensibilisation.</p>
        <master-title-2>Les événements en lien avec des partenaires, journées nationales</master-title-2>
        <p class="large-text"> Cela comprend la réalisation d'actions par exemple lors de la semaine du goût, la Semaine Étudiante de l'Ecologie et de la Solidarité (SEES) En 2023, , l'association a organisé sa propre journée de sensibilisation : la JADE, Journée de l'Alimentation Durable Étudiante. Un atelier cuisine, une grande tablée et un vernissage photo accompagné d'un buffet pédagogique étaient au programme. Ce fut l'occasion de sensibiliser les étudiant.e.s à l'alimentation durable de différentes manières.</p>
        <legumes-line />
        <p class="large-text">Étudiant.e, association étudiante, partenaire, vous souhaitez mettre en place une action de sensibilisation à destination d'étudiant.e.s ? <a href="/contact">Contactez-nous</a> !</p>
      </b-col>
    </b-row>
    <tb-footer />
  </b-container>
</template>

<script>
import MasterTitle2 from '@/components/utils/MasterTitle2.vue'
import PhotoTitle from '@/components/utils/PhotoTitle.vue'
import PhotoText from '@/components/utils/PhotoText.vue'
import LegumesLine from '@/components/utils/LegumesLine.vue'
import TbFooter from '@/components/utils/TbFooter.vue'

export default {
  name: "Sensibiliser",
  components: {
    MasterTitle2,
    PhotoTitle,
    PhotoText,
    LegumesLine,
    TbFooter
  }
}
</script>

<style scoped>
#asso-page {
  display: flex;
  flex-direction: column;
  justify-content: top;
}
</style>