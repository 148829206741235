<template>
  <b-container fluid="sm" id="asso-page">
    <photo-title
      :image="require('@/../public/assets/photos/accompagner_low.jpg')"
    >Accompagner</photo-title>

    <b-row class="justify-content-md-center" style="margin-top: 1rem;">
      <b-col lg=10 align="center">
        <p class="large-text">L'association souhaite faciliter le transfert de connaissances et accélérer le déploiement de projets alimentaires durables et responsables pour les étudiant.e.s. Pour cela, elle se tient disponible pour faire part de son retour d'expérience et de son expertise auprès des personnes et structures qui souhaitent :</p>
      </b-col>
    </b-row>

    <accompagner-objectifs :nextTitle="true"/>

    <b-row class="justify-content-md-center" style="margin-top: 1rem;">
      <b-col lg=12 align="center">
        <p class="large-text">La Gamelle Étudiante est une association étudiante implantée à Metz qui promeut une alimentation durable et de qualité aux étudiant.e.s de l'Université de Lorraine tout en facilitant le lien social entre elles.eux. Tim & Bastien a participé à  plusieurs échanges de structuration du projet.</p>
        <p class="large-text">Plus d'informations : <a href="https://lefilon.org/projets/la-gamelle-etudiante/">ici</a>.</p>
        <p class="large-text">L'association a également fait part de son retour d'expérience auprès d'étudiant.e.s en BTS DATR (Développement et Animation des Territoires Ruraux) de Fougères (Bretagne). Leurs travaux menés en lien avec le Pôle ESS du Pays de Fougères portaient sur l'étude de l'alimentation des jeunes 16 - 25 ans. Un intérêt particulier a été porté à l'identification de solutions déployables en milieu rural. Ce témoignage a permis aux étudiant.e.s d'en apprendre davantage sur la conception et le développement des actions de l'association.</p>
        <photo-text :image="require('@/../public/assets/accompagner/Dessin_sara.jpg')" width="60%"/>
        <master-title-2>Rédaction d'un plaidoyer</master-title-2>
        <p class="large-text">Face à une précarité alimentaire étudiante grandissante et aux enjeux écologiques et sociaux actuels, l'association construit depuis 2022 un plaidoyer pour une alimentation étudiante saine, durable et accessible. Constatant un besoin de liens entre les acteurs du territoire, l'association via ce plaidoyer souhaite proposer des pistes de réflexions, créer du lien et faciliter une mise en œuvre opérationnelle d'actions.</p>
        <p class="large-text">Une 20aine d'échanges ont été réalisés entre 2022 et 2023 avec des acteurs locaux et nationaux de l'alimentation, de la santé, de la vie étudiante (CROUS, ARS, Villes de Nancy, Métropole, élu.e.s étudiant.e.s …). L'objectif était de comprendre le système alimentaire étudiant, les initiatives inspirantes, les freins et les leviers mobilisables.</p>
        <p class="large-text">La première moitié de l'année 2023 nous a permis de construire une première version comprenant 10 propositions. Ces propositions ont été enrichies des retours étudiant.e.s que nous avons pu avoir sur les campus et des constats réalisés par l'association dans le cadre de ses activités sur les campus depuis septembre 2021. Ce qui a permis de faire évoluer notre structure et le contenu de notre plaidoyer.</p>
        <p class="large-text">L'objectif pour 2024 est d'arriver à un texte complet et diffusable portant notre vision de l'avenir de l'alimentation étudiante à l'échelle de la Lorraine voire de la France. Nous voulons également aboutir à une stratégie de communication et de diffusion pour d'engager les politiques alimentaires étudiantes  vers une meilleure prise en compte des enjeux de précarité alimentaire et d'alimentation durable</p>
        <p class="large-text">Par ailleurs, la participation et l'intervention de l'association à des événements et réseaux d'ampleur locaux à nationaux permettent de nourrir ce projet et plus largement l'ensemble des réflexions et projets de la structure.</p>
      </b-col>
    </b-row>
    <tb-footer />
  </b-container>
</template>

<script>
import MasterTitle2 from '@/components/utils/MasterTitle2.vue'
import PhotoTitle from '@/components/utils/PhotoTitle.vue'
import PhotoText from '@/components/utils/PhotoText.vue'
import TbFooter from '@/components/utils/TbFooter.vue'
import AccompagnerObjectifs from '@/components/accompagner/AccompagnerObjectifs.vue'

export default {
  name: "Accompagner",
  components: {
    MasterTitle2,
    PhotoTitle,
    PhotoText,
    TbFooter,
    AccompagnerObjectifs,
  }
}
</script>

<style scoped>
#asso-page {
  display: flex;
  flex-direction: column;
  justify-content: top;
}
</style>